<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="事前協議書複製" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!--基本情報-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">基本情報</h3>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>協議書管理番号</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                  :value.sync="form.priorConsultationNo"
                  inputType="text"
                  placeholder="協議書管理番号"
                  tooltip="100文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="100"
                  :isValid.sync="validate.priorConsultationNo.isValid"
                  :errMsg.sync="validate.priorConsultationNo.errMsg"
              />
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>搬入先自治体</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                  :value.sync="form.targetMunicipalityName"
                  inputType="text"
                  placeholder="搬入先自治体"
                  tooltip="50文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  :isValid.sync="validate.targetMunicipalityName.isValid"
                  :errMsg.sync="validate.targetMunicipalityName.errMsg"
              />
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>排出事業者</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox
                  class="mid"
                  :value.sync="form.generateCompanyInfo"
                  :validation="validateJustBlank"
                  :change="changeGenerateCompany"
                  :isValid.sync="validate.generateCompany.isValid"
                  :errMsg.sync="validate.generateCompany.errMsg"
              >
                <option
                    v-for="(opt, index) in generateCompanyList"
                    :key="`gc-opt-${index}`"
                    :value="opt"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox
                  :value.sync="form.jwnetItemTypeInfo.id"
                  :validation="validateJustBlank"
                  :change="jwnetItemChangeHandler"
                  :isValid.sync="validate.jwnetItemType.isValid"
                  :errMsg.sync="validate.jwnetItemType.errMsg"
              >
                <option
                    v-for="item in generateJwnetItems"
                    :key="'jwnet-' + item.id"
                    :value="item.id"
                >
                  {{ item.name }}
                </option>
              </Selectbox>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>ルート</label>
            </div>
            <div class="c-inputWrap__items" :class="{ disabled: !form.jwnetItemTypeInfo || !form.generateCompanyInfo}">
              <div class="non_side_layout">
                <div
                    class="c-btn secondary small l-item"
                    @click="showRouteSelectModal()"
                >
                  ルート選択
                </div>
              </div>
              <p v-if="validate.routeId.errMsg" class="c-input__errMsg l-area">{{ validate.routeId.errMsg }}</p>
            </div>
          </div>
          <div v-if="selectedRouteInfoList" class="c-infoList__wrap">
            <div v-if="selectedRouteInfoList.length" class="c-infoList scroll l-box">
              <div class="c-infoList__row head">
                <div class="c-infoList__cell md-s">ルート名</div>
                <div class="c-infoList__cell md-s">排出事業場</div>
                <div class="c-infoList__cell md-s">廃棄物名称</div>
                <div class="c-infoList__cell md">運搬事業者</div>
                <div class="c-infoList__cell md-s">処分事業者</div>
                <div class="c-infoList__cell md-s">処分場住所</div>
                <div class="c-infoList__cell cb"></div>
              </div>
              <div
                  v-for="item of this.selectedRouteInfoList"
                  :key="item.routeId"
                  class="c-infoList__row list"
              >
                <div class="c-infoList__cell md-s text-dotted">{{ item.routeName }}</div>
                <div class="c-infoList__cell md-s text-dotted">{{ item.generateStoreInfo ? item.generateStoreInfo.name : "" }}</div>
                <div class="c-infoList__cell md-s text-dotted">{{ item.wasteNameInfo ? item.wasteNameInfo.name : "" }}</div>
                <div class="c-infoList__cell md l-flex">
                  <CommaText :items="item.collectCompanyList" arg="name" :maxLength="15" />
                </div>
                <div class="c-infoList__cell md-s text-dotted">{{ item.disposalCompany.name }}</div>
                <div class="c-infoList__cell md-s text-dotted">
                  {{ item.disposalSiteAddressInfo.zipCode }}
                  {{ item.disposalSiteAddressInfo.prefecturesName }}
                  {{ item.disposalSiteAddressInfo.address }}
                  {{ item.disposalSiteAddressInfo.buildingName }}
                </div>
                <div
                    v-if="item"
                    class="c-infoList__cell cb"
                >
                  <a @click="removeRoute(item.routeId)">
                    <span class="c-btn-icon delete secondary small l-item-l"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>上限数量</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                    :value.sync="form.limitQuantity"
                    placeholder="上限数量"
                    inputType="number"
                    tooltip="半角数値で入力してください"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.limitQuantity.isValid"
                    :errMsg.sync="validate.limitQuantity.errMsg"
                />
                <div class="c-inputWrap__items">
                  {{ form.limitQuantityUnitType ? form.limitQuantityUnitType.name : "" }}
                </div>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>協議書期間</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputDate
                    :value.sync="form.priorConsultationStartDate"
                    placeholder="0000/00/00"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.priorConsultationStartDate.isValid"
                    :errMsg.sync="validate.priorConsultationStartDate.errMsg"
                />
                <span>〜</span>
                <InputDate
                    :value.sync="form.priorConsultationEndDate"
                    placeholder="0000/00/00"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.priorConsultationEndDate.isValid"
                    :errMsg.sync="validate.priorConsultationEndDate.errMsg"
                />
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>集計期間</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                  v-for="(
                    countTerm, countTermIndex
                  ) in form.countTermList"
                  :key="countTermIndex"
                  class="l-flex l-area"
              >
                <div class="c-inputWrap__items__cols">
                <InputDate
                    :value.sync="countTerm.countTermStartDate"
                    placeholder="0000/00/00"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.countTermStartDateList[countTermIndex].isValid"
                    :errMsg.sync="validate.countTermStartDateList[countTermIndex].errMsg"
                />
                <span>〜</span>
                <InputDate
                    :value.sync="countTerm.countTermEndDate"
                    placeholder="0000/00/00"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.countTermEndDateList[countTermIndex].isValid"
                    :errMsg.sync="validate.countTermEndDateList[countTermIndex].errMsg"
                />
                </div>
                <div class="c-inputWrap__items__cols l-area-side">
                  <label>警告基準値</label>
                </div>
                <div class="c-inputWrap__items__cols l-area-side">
                  <div class="c-inputWrap__items__cols">
                    <InputText
                        :value.sync="countTerm.alertValue"
                        inputType="number"
                        tooltip="半角数値で入力してください"
                        :validation="validateNumberOrFloat"
                        :isValid.sync="validate.alertValueList[countTermIndex].isValid"
                        :errMsg.sync="validate.alertValueList[countTermIndex].errMsg"
                    />
                  </div>
                  <label>%</label>
                </div>
                <div
                    v-if="countTermIndex"
                    class="c-infoList__cell l-flex"
                >
                  <a @click="removeTerm(countTerm, countTermIndex)">
                    <span class="c-btn-icon delete secondary small l-item-l"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
              class="c-btn secondary add sm l-area"
              @click="addTerm()"
          >
            期間を追加
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>対象契約</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.entrustIds }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>備考</label>
            </div>
            <div class="c-inputWrap__items">
              <MyTextarea
                  :value.sync="form.remarks"
                  rows="6"
                  placeholder="入力してください"
                  :validation="validateJustBlank"
              ></MyTextarea>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>添付ファイル</label>
            </div>
            <div class="c-inputWrap__items">
              <input
                  :id="'file-upload-'"
                  type="file"
                  accept="application/pdf"
                  @change="(e) => uploadFile(e)"
              />
              <label class="c-btn secondary upload sm l-item" :for="'file-upload-'">
                ファイル選択
              </label>
              <div v-if="form.fileName" class="c-inputWrap__items">
                <a @click="downloadFile(form.fileName)" class="c-text text-link c-entrustText__text">
                  {{ form.fileName }}
                </a>
              </div>
              <div
                  v-if="form.fileName"
                  class="c-infoList__cell l-flex"
              >
                <a @click="removeFileName()">
                  <span class="c-btn-icon delete secondary small l-item-l"></span>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="checkRequiredHandlerForDuplicate">同自治体への協議書を続けて登録</div>
          <div class="c-btn primary mid" @click="checkRequiredHandler">登録</div>
        </div>
      </div>
    </main>

    <!-- 次へ確認モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          事前協議書を登録します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!-- 同自治体への協議書を続けて登録を確認モーダル -->
    <div class="c-modal" v-show="isContinueRegistConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          同自治体への協議書を続けて登録します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandlerForDuplicate">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!--ルート選択モーダル-->
    <div class="c-modal xlg" v-if="isRouteSelectModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">ルート選択</h5>
          <span
              class="c-modal__head__close"
              @click="isRouteSelectModalShow = false"
          ></span>
        </div>
        <div class="c-modal__body">
          <!--検索条件開始-->
          <div class="l-area">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>廃棄物種類</label>
              </div>
              <div v-if="form.jwnetItemTypeInfo" class="c-inputWrap__items">
                {{ form.jwnetItemTypeInfo.name }}
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>排出事業者</label>
              </div>
              <div v-if="form.generateCompanyInfo" class="c-inputWrap__items">
                {{ form.generateCompanyInfo.name }}
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols search-wrap">
                <div>
                  <span class="text-label">都道府県</span>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                          name="prefecture"
                          :value.sync="routeModalSearchParam.prefecture"
                          :validation="validateJustBlank"
                      >
                        <option :value="null"></option>
                        <option
                            v-for="prefecture in prefectures"
                            :key="prefecture.id"
                            :value="prefecture"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>

                <div>
                  <span class="text-label">処分事業者</span>
                  <InputText
                      class="xlg"
                      :value.sync="routeModalSearchParam.disposalCompanyName"
                      inputType="text"
                      placeholder="処分事業者名称を入力"
                      :validation="validateMaxLen"
                      :validateArg="100"
                  >
                  </InputText>
                </div>
              </div>
            </div>
          </div>

          <div class="l-container">
            <div class="l-btns l-block-lg">
              <div class="c-btn secondary small" @click="resetRouteModalSearchHandler">
                条件をリセット
              </div>
              <div class="c-btn primary small" @click="routeModalSearchHandler">検索</div>
            </div>
          </div>
          <!--検索条件終了-->
          <!--検索結果表示開始-->
          <div class="c-infoList__row head">
            <div class="c-infoList__cell xsm"></div>
            <div class="c-infoList__cell md">ルート名称</div>
            <div class="c-infoList__cell md">廃棄物名称</div>
            <div class="c-infoList__cell md">排出事業場名</div>
            <div class="c-infoList__cell md">運搬事業者</div>
            <div class="c-infoList__cell md">処分事業者</div>
            <div class="c-infoList__cell md">処分場住所</div>
          </div>
          <div
              v-for="item of this.narrowedRoutesList"
              :key="item.routeId"
              class="c-infoList__row list"
              :class="{ notActive: !item.isActive }"
          >
            <div class="c-infoList__cell xsm">
              <div class="c-checkbox">
                <input
                    :id="'route' + item.routeId"
                    type="checkbox"
                    :value="item.routeId"
                    v-model="selectedRoute"
                />
                <label class="c-checkbox__label" :for="'route' + item.routeId">
                  <span class="c-checkbox__box"></span>
                </label>
              </div>
            </div>
            <div class="c-infoList__cell md text-dotted">{{ item.routeName }}</div>
            <div class="c-infoList__cell md text-dotted">{{ item.wasteNameInfo.name }}</div>
            <div class="c-infoList__cell md text-dotted">{{ item.generateStoreInfo.name }}</div>
            <div class="c-infoList__cell md l-flex">
              <CommaText :items="item.collectCompanyList" arg="name" :maxLength="15" />
            </div>
            <div class="c-infoList__cell md text-dotted">{{ item.disposalCompany.name }}</div>
            <div class="c-infoList__cell md text-dotted">
              {{ item.disposalSiteAddressInfo.zipCode }}
              {{ item.disposalSiteAddressInfo.prefecturesName }}
              {{ item.disposalSiteAddressInfo.address }}
              {{ item.disposalSiteAddressInfo.buildingName }}
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
              class="c-btn primary small"
              @click="clickSetRoute(selectedRoute)"
          >
            OK
          </div>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Selectbox from "@/components/parts/input/Selectbox";
import MyTextarea from "@/components/parts/input/MyTextarea";
import CommaText from "@/components/parts/CommaText";
import callApi from "./mixins/callApi";
import countTerm from "./mixins/countTerm";
import route from "./mixins/route";
import validation from "@/mixin/validation";
import axios from "axios";
import {API_URL} from "@/const";
import common from "@/mixin/common";
import validate from "@/pages/priorconsultation/duplicate/mixins/validate";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import validateEmptyTerm from "@/pages/priorconsultation/common/validateEmptyTerm";

export default {
  name: "priorConsultation-duplicate",
  components: {
    InputText,
    InputDate,
    Selectbox,
    MyTextarea,
    ModalFooterButtons,
    CommaText,
  },
  mixins: [
    validation,
    callApi,
    route,
    countTerm,
    common,
    validate,
    validateEmptyTerm,
  ],
  data() {
    return {
      isRouteSelectModalShow: false,
      isContinueRegistConfirmModalShow: false,
      isConfirmModalShow: false,
      narrowedRoutesList: [],
      routesInfo: [],
      selectedRoute: [],
      errMsgs: [],
      processing: false,
      // ルート選択肢モーダル検索パラメータ
      routeModalSearchParam: {
        prefecture: '',// 都道府県
        disposalCompanyName: '', // 処分事業者名
      },
    };
  },

  methods: {
    //排出事業者変更時
    //排出事業者に紐づく廃棄物種類選択肢取得API
    changeGenerateCompany() {
      //ルートをリセット
      this.form.routeList = [];
      this.form.entrustIds = null;
      this.selectedRouteInfoList = [];
      this.selectedRoute = [];

      this.generateJwnetItems = [];
      const URL = API_URL.ENTRUST.GENERATE_JWNET_ITEM_TYPE + this.form.generateCompanyInfo.id;
      return axios
          .get(URL)
          .then((res) => {
            this.generateJwnetItems = res.data.jwnetItems;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    //ルートを削除
    removeRoute(routeId) {
      this.selectedRouteInfoList = this.selectedRouteInfoList.filter(
          (r) => r.routeId != routeId
      )
      var entrustIdsList = this.selectedRouteInfoList.map((r) => r.entrustId);
      this.form.entrustIds = entrustIdsList.filter(
          (val, index) => entrustIdsList.indexOf(val) == index
      ).join(",");
      this.selectedRoute = this.selectedRoute.filter(
          (r) => r != routeId
      )
      this.form.routeList = this.form.routeList.filter(
          (r) => r.id != routeId
      )
    },

    // 「ルート選択」ボタンを押すと、選択モーダルを表示する
    showRouteSelectModal() {
      this.getPrefecturesApi();
      this.setRouteSelectModalSearchParam();
      this.isRouteSelectModalShow = true;
      this.routeModalSearchHandler();
    },

    // 設定した条件があればそのままにひょうじする
    setRouteSelectModalSearchParam() {
      const currentSearchParam = this.routeModalSearchParam;
      this.routeModalSearchParam = {
        prefecture: currentSearchParam.prefecture !== "" ? currentSearchParam.prefecture : "",
        disposalCompanyName: currentSearchParam.disposalCompanyName !== "" ? currentSearchParam.disposalCompanyName : "",
      }
    },

    // ルート選択肢モーダルに「検索」ボタンを押す処理
    routeModalSearchHandler() {
      this.getPriorConsultationRouteSearchApi();
    },


    // ルート選択肢モーダルに「検索条件をリセット」ボタンを押すと
    resetRouteModalSearchHandler() {
      this.routeModalSearchParam = {
        prefecture: "",
        disposalCompanyName: "",
      };
    },

    clickSetRoute(routeId) {
      this.form.routeList = [];
      this.form.routeList.id = routeId;
      this.isRouteSelectModalShow = false;
      this.onChangeRouteHandler();
    },

    // 事前協議書ルート検索API
    getPriorConsultationRouteSearchApi() {
      return axios
          .get(this.getConsultationRouteListSearchApiUrl())
          .then((res) => {
            this.routesInfo = res.data.priorConsultationRouteList;
            this.narrowedRoutesList = [...this.routesInfo];
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 事前協議書ルート選択肢検索Urlの作成
    getConsultationRouteListSearchApiUrl() {
      const params = new URLSearchParams();
      if (this.form.generateCompanyInfo) params.append("generateCompanyId", this.form.generateCompanyInfo.id);
      if (this.form.jwnetItemTypeInfo) params.append("jwnetItemTypeId", this.form.jwnetItemTypeInfo.id);
      if (this.routeModalSearchParam.prefecture) params.append("prefecturesId", this.routeModalSearchParam.prefecture.id);
      if (this.routeModalSearchParam.disposalCompanyName) params.append("disposalCompanyName", this.routeModalSearchParam.disposalCompanyName);

      return API_URL.PRIORCONSULTATION.ROUTE_SEARCH+ '?' + params.toString();
    },

    // 画面内下部の登録押下
    async checkRequiredHandler() {
      this.errMsgs = [];
      const isValid = await this.runValidate();

      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.isConfirmModalShow = false;
        return;
      }
      this.isConfirmModalShow = true;
    },

    // 同自治体への協議書を続けて登録を押下
    async checkRequiredHandlerForDuplicate() {
      this.errMsgs = [];
      const isValid = await this.runValidate();

      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.isContinueRegistConfirmModalShow = false;
        return;
      }
      this.isContinueRegistConfirmModalShow = true;
    },

    // ファイルをアップロード
    uploadFile(e) {
      if (!e.target.files[0]) return;
      const file = e.target.files[0] || e.dataTransfer.files[0];
      this.uploadApi(file).then((path) => {
        this.form.filePath = path;
        this.form.fileName = file.name;
      });
    },

    // ファイルダウンロード
    downloadFile(fileName) {
      this.downloadPriorConsultationFileApi(this.$route.params.id, fileName);
    },

    // モーダル内戻る押下
    closeConfirmModalHandler() {
      this.isConfirmModalShow = false;
      this.isContinueRegistConfirmModalShow = false;
      this.postData = null;
    },

    //戻るボタン押下
    backHandler() {
      this.$router.push(`/priorconsultation/${this.$route.params.id}`);
    },

    // モーダル内登録押下
    registerHandler() {
      this.postForm(false);
    },

    // 同自治体への協議書を続けて登録確認モーダル押下
    registerHandlerForDuplicate() {
      this.postForm(true);
    },

    postForm(isContinueRegist) {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      const postData = JSON.parse(JSON.stringify(this.form));
      // フォーム整形
      postData.generateCompanyId = this.form.generateCompanyInfo.id;
      delete postData.generateCompanyInfo;
      postData.jwnetItemTypeId = this.form.jwnetItemTypeInfo.id;
      delete postData.jwnetItemTypeInfo;
      //固定
      postData.limitQuantityUnitTypeId = 1;

      axios.post(API_URL.PRIORCONSULTATION.CREATE, postData)
          .then((res) => {
            this.processing = false;
            const priorConsultationId = res.data.priorConsultationId;
            if (isContinueRegist) {
              //複製画面へ
              this.$router.push(`/priorconsultation/duplicate/${priorConsultationId}`).then(() => {
                this.priorConsultationDetailApi(priorConsultationId);
              });
            } else {
              // 一覧画面へ
              this.$router.push(`/priorconsultation`);
            }
            this.isConfirmModalShow = false;
            this.isContinueRegistConfirmModalShow = false;

          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.processing = false; // 2重submit解除
            this.isConfirmModalShow = false;
            this.isContinueRegistConfirmModalShow = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },

    removeFileName() {
      this.form.fileName = null;
      this.form.filePath = null;
    }

  },

  computed: {
  },

  mounted() {
    this.getGeneratorApi();
    this.priorConsultationDetailApi(this.$route.params.id).then( () => {
      this.getGenerateJwnetItems();
    })
  },
};
</script>

